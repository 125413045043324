import React from "react";
import "./IMIDCustomer.styles.css";
import { Img, Text, Button, FloatingInput } from "components";
import { CustomerDetailIcon } from "components/CustomerDetailsIcon/CustomerDetailIcon";
import TabComponent from "components/CustomerTab/CustomerTab";
import BackLogoIconComponent from "components/BackLogoIcon/BackLogoIconComponent";
import ImageGroupComponent from "components/ImageGroupComponent/ImageGroupComponent";
import CreateCustomerForm from "components/CreateCustomerForm/CreateCustomerForm";
import { useSelector } from "react-redux"

const IMIDCustomerPage = () => {
  const user = useSelector((state) => state.auth.userData)
  console.log("user ", user)
  return (
    <>
      <div>
        {/* <ImageGroupComponent /> */}
        <CreateCustomerForm />
      </div>
      {/* <p>{user.email}</p> */}
    </>
  );
};

export default IMIDCustomerPage;
