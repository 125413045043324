import React from "react";
import lightBulb from "../../assets/images/imageIcon/img_lightbulb.svg"
import { Button, Img, Text } from "components";

const IMIDCustomerTwoDashboardcard = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="bg-white_A700 border-[1px] border-gray_51 border-solid flex items-start justify-start p-[24px] sm:px-[20px] rounded-[10px] w-[100%]">
          <div className="flex flex-col gap-[20px] items-start justify-center mb-[5px] mt-[10px] self-stretch w-[auto]">
            <Button className="bg-gray_51 border-[1px] border-gray_51 border-solid flex h-[40px] items-center justify-center p-[10px] rounded-[50%] w-[40px]">
              <Img
                src={lightBulb}
                className="h-[20px]"
                alt="user"
              />
            </Button>
            <div className="flex flex-col gap-[5px] items-start justify-center self-stretch w-[auto]">
              <Text
                className="font-montserrat text-left text-light_blue_800 w-[auto]"
                as="h5"
                variant="h5"
              >
                {props?.twenty}
              </Text>
              <Text
                className="font-montserrat font-normal not-italic text-gray_601 text-left w-[auto]"
                variant="body4"
              >
                {props?.totalcustomers}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

IMIDCustomerTwoDashboardcard.defaultProps = {
  twenty: "-",
  totalcustomers: "Total Customers",
};

export default IMIDCustomerTwoDashboardcard;
