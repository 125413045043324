import React, { useState } from "react";
import "./tenant.styles.css";
import { useNavigate, Link } from "react-router-dom";
import { Img, Text, Button, Input } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import BackArrow from "../../assets/images/imageIcon/arrow-left.svg";
const ResetPasswordTenantComponent = () => {
  let [loading, setLoading] = useState(false);
  return (
    <>
      <div className="login-form-wrapper">
        <div className="back-wrapper">
          <img src={BackArrow} alt="back" />
          <h3>Back</h3>
        </div>
        <div className="auth-header">
          <h2>Reset Password</h2>
          <h4>
            Your new password must be different from the previous one
          </h4>

          <form
          >
             <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
              className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
              name="password"
              placeholder="********************"
              labelText="Enter new password"
              type="password"
              // value={password}
              // onChange={(e) => setPassword(e.target.value)}
              prefix={
                <Img
                  src="images/img_checkmark.svg"
                  className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                  alt="eye"
                />
              }
              // ref={passwordRef}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
              className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
              name="password"
              placeholder="********************"
              labelText="Confirm New Password"
              type="password"
              // value={password}
              // onChange={(e) => setPassword(e.target.value)}
              prefix={
                <Img
                  src="images/img_checkmark.svg"
                  className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                  alt="eye"
                />
              }
              // ref={passwordRef}
            ></Input>
           
            <Button
              className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[400px]"
              // onClick={handler}
            >
              {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Set New Password"}
            </Button>
            <div className="flex justify-end">
              <Text
                className="font-gtwalsheimpro font-light 
                   mt-[27px] 
                  text-[16px] text-gray_800 text-left 
                  w-[100%]"
                variant="body4"
              >
                Don't have an account?{" "}
                <Link className="font-bold" to={"/forgetpassword"}>
                  Create one
                </Link>
              </Text>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordTenantComponent;
