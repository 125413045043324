import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input } from "components";
import { useSelector } from "react-redux";
import BuildingGroupComponent from "components/BuildingGroup/BuildingGroupComponent";
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";

const CreateBuildingForm = () => {
  const [contactName, setContactName] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [contactEmailAddress, setContactEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [numberOfApartments, setNumberOfApartments] = useState("");
  const [bulkMeterNumber, setBulkMeterNumber] = useState("");
  const [bulkMeterAccountNumber, setBulkMeterAccountNumber] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // const token = useSelector((state) => state.auth.authenticationData.idToken)
  const token = window.localStorage.getItem("token");

  const baseUrl = process.env.REACT_APP_IMID_SERVICE_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = `${baseUrl}/building`;

    const data = {
      contactName,
      contactPhoneNumber,
      contactEmailAddress,
      address,
      city,
      province,
      postalCode,
      country,
      numberOfApartments,
      bulkMeterAccountNumber,
      bulkMeterNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.post(url, data, { headers });
      if (res.status === 200) {
        setContactName("");
        setContactPhoneNumber("");
        setContactEmailAddress("");
        setAddress("");
        setCity("");
        setProvince("");
        setPostalCode("");
        setCountry("");
        setNumberOfApartments("");
        setBulkMeterNumber("");
        setBulkMeterAccountNumber("");
      } else {
        setError("Error sending data");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="img-wrapper">
          <div className="sub-img-wrapper">
            <h3 className="building-header">Add Building</h3>
          </div>
          {/* <h3 className="building-header">Add Building.</h3> */}
        </div>
        <div className="customer-form-wrapper">
          <div>
            {/* <Input
            wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
            className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
            labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
            name="Building Name"
            labelText="Building Name"
          ></Input> */}
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Number of Apartments"
              type="number"
              labelText="Number of Apartments"
              value={numberOfApartments}
              onChange={(e) => setNumberOfApartments(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="address"
              type="text"
              labelText="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Bulk Meter"
              type="number"
              labelText="Bulk Meter"
              value={bulkMeterNumber}
              onChange={(e) => setBulkMeterNumber(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Contact Person"
              labelText="Contact Person"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Bulk Meter Account"
              type="number"
              labelText="Bulk Meter Account"
              value={bulkMeterAccountNumber}
              onChange={(e) => setBulkMeterAccountNumber(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="City"
              labelText="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            ></Input>
          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Zip Code"
              labelText="Zip Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Country"
              type="text"
              labelText="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="State/Province"
              type="text"
              labelText="State/Province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="email"
              labelText="Contact Email Address"
              value={contactEmailAddress}
              onChange={(e) => setContactEmailAddress(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              type="number"
              labelText="Contact Phone Number"
              value={contactPhoneNumber}
              onChange={(e) => setContactPhoneNumber(e.target.value)}
            ></Input>

            <div className="customer-button">
              <Button type="submit">
                {loading ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Add Building"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateBuildingForm;
