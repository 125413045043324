import axios from "axios";

const API_KEY = "prIXl0KECAoWSYbeLWCS30pz0Mwxk0w1oAd2tEsg";

const baseUrl = process.env.REACT_APP_IMID_DATA_SERVICE_BASE_URL;
const apiKey = process.env.REACT_APP_IMID_DATA_SERVICE_API_KEY;

const getData = async (deviceData) => {
  axios.defaults.headers.get["x-api-key"] =
    apiKey;


  ///dev/imid-device/history?
  //deviceId=000300006438&
  //attribute=activeEnergyTotal&
  //from=2023-03-10T00:00:00&
  //to=2023-03-24T22:00:00&
  //type=ProfileData
  // return await fetch(`${baseUrl}/history?deviceId=${deviceData.deviceId}&attribute=${deviceData.attribute}&from=${deviceData.from}&to=${deviceData.to}&type=${deviceData.type}`, {
  //   headers: {
  //     "x-api-key": API_KEY,
  //   }
  // });


  try {
    return await axios.get(`${baseUrl}/history`, {
      params: {
        deviceId: deviceData.deviceId,
        attribute: deviceData.attribute,
        from: deviceData.from,
        to: deviceData.to,
        type: deviceData.type,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message);
  }

  // return result.data;
};

const getLastMonthUsage = async (deviceId) => {
  try {
    const result = await axios.get(`${baseUrl}/billing`, {
      params: {
        deviceId,
        attribute: "activeEnergyTotal",
        type: "MonthlyBillingData",
        lastBillingData: true
      },
    });
    console.log("month billing data: " , result)
    const obj = result?.data?.data || "{}";
    const data = JSON.parse(obj);
    console.log("Value: " , data);
    const values = data?.historicalRecords?.activeEnergyTotal?.billingValuesList || []
    console.log("Billing values: " , values)
    if (values.length > 0) {
      return values[0].value
    }
    return undefined;
  } catch (error) {
    console.log(error);
  }

}

const transformData = (data, parameter) => {
  const d = JSON.parse(data);
  console.log("JSON Data: ", d);
  const resp = [
    {
      id: parameter,
      color: "hsl(258, 70%, 50%)",
      data: [],
    },
  ];

  console.log("response: ", resp[0]);
  d.historicalRecords[parameter].billingValuesList.forEach((e, i) => {
    resp[0].data.push({ y: e.value, x: new Date(e.timestamp) });
  });

  console.log("formated data")
  console.log(resp)
  return resp;
};

const transformDataForBarChart = (data, parameter) => {
  const d = JSON.parse(data);
  console.log("JSON Data: ", d);
  const resp = [
    {
      indexBy: "timeStamp",
      yLegend: "Date/time",
      xLegend: "consumption",
      keys: ["Energy consumed", "amount"], 
      data: [],
    },
  ];

  console.log("response: ", resp[0]);
  d.historicalRecords[parameter].billingValuesList.forEach((e, i) => {
    resp[0].data.push({ y: e.value, x: new Date(e.timestamp) });
  });

  console.log("formated data")
  console.log(resp)
  return resp;
};

export { getData, transformData, transformDataForBarChart, getLastMonthUsage};
