import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";

const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      labelText,
      labelClass,
      children,
      value,
      errors = [],
      label,
      prefix,
      suffix,
      onChange,

      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} 
               
               
              `}
        >
          {!!prefix && prefix}
          <div className="flex-col w-[100%]">
            <label className={labelClass}>{labelText}</label>
            <input
              ref={ref}
              className={`${className} bg-transparent border-0`}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              {...restProps}
            />
          </div>
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  wrapClassName: "",
  className: "",
  labelClass: "",
  name: "",
  placeholder: "",
  type: "text",
  onChange: undefined,
};

export { Input };
