import React, { useRef, useState } from "react";
import "./tenant.styles.css";
import BackArrow from "../../assets/images/imageIcon/arrow-left.svg";
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";

function EmailVerification() {
  let [loading, setLoading] = useState(false);
  const inputsRef = useRef([]);
  const [values, setValues] = useState(new Array(4).fill(""));
  let clipData;

  const handleKeyUp = (event, index) => {
    if (event.code === "Backspace" && hasNoValue(index)) {
      if (index > 0) inputsRef.current[index - 1].focus();
    } else if (event.target.value !== "") {
      index < inputsRef.current.length - 1
        ? inputsRef.current[index + 1].focus()
        : event.target.blur();
    }
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = event.target.value;
      return newValues;
    });
  };

  const handleInput = (event) => {
    if (event.target.value > 10) {
      event.target.value = event.target.value % 10;
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    clipData = event.clipboardData.getData("text/plain").split("");
    fillData(index);
  };

  const fillData = (index) => {
    for (let i = index; i < inputsRef.current.length; i++) {
      inputsRef.current[i].value = clipData.shift();
    }
  };

  const hasNoValue = (index) => {
    if (values[index] || values[index] === 0) return false;
    return true;
  };

  return (
    <div className="login-form-wrapper-verify">
      <div className="back-wrapper-forgot-password">
        <img src={BackArrow} alt="back" />
        <h3>Back</h3>
      </div>
      <div className="auth-header-verify forgot-password-header">
        <h3>Verify your Email Address</h3>
        <h4>Please enter the 4 digits code</h4>
      </div>
      <div className="row">
        {[...Array(4)].map((_, index) => (
          <input
            key={index}
            ref={(el) => (inputsRef.current[index] = el)}
            type="number"
            min="0"
            max="9"
            step="1"
            aria-label={`${index + 1} digit`}
            onKeyUp={(event) => handleKeyUp(event, index)}
            onInput={handleInput}
            onPaste={(event) => handlePaste(event, index)}
            className="input-box"
          />
        ))}
      </div>
      <div className="button-wrapper-verify">
        <Button
          className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[400px]"
          // onClick={handler}
        >
          {loading ? (
            <ClipLoader size={20} color={"#ffffff"} />
          ) : (
            "Verify Email Address"
          )}
        </Button>
      </div>
    </div>
  );
}

export default EmailVerification;
