import React, { useState, useRef } from "react";
import { Img, Text, Button, Input } from "components";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import ImageIcon from "../../assets/images/imageIcon/img_group8_gray_51.svg";
import "./CustomerFormWrapper.css";

const CreateCustomerForm = () => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [customerType, setCustomerType] = useState("ENERGY_COMPANY");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile.name);
  };

  const token = window.localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url =
      `${baseUrl}/customer`;

    const data = {
      name,
      phoneNumber,
      emailAddress,
      city,
      province,
      postalCode,
      country,
      customerType,
      address,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.post(url, data, { headers });
      if (res.status === 200) {
        setName("");
        setPhoneNumber("");
        setEmailAddress("");
        setCity("");
        setProvince("");
        setPostalCode("");
        setCountry("");
        setCustomerType(customerType);
        setAddress("");
      } else {
        setError("Error sending Data");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className="customer-form-wrapper">
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="user-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              labelText="Company/Customer Name"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-blue-500 peer-focus:dark:text-blue-500"
              name="emailaddress"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              labelText="Email Address"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="number"
              labelText="Phone Number"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              labelText="Address"
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              labelText="City"
            ></Input>


          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              labelText="Province"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Zip Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              labelText="Zip Code"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              labelText="Country"
            ></Input>
            {/*<Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Customer Type"
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
              labelText="Customer Type"
            ></Input>*/}
            <select value={customerType} onChange={(e) => setCustomerType(e.target.value)} className='w-[100%] text-sm bg-gray_100 border-[1px] text-[16px] text-gray_600 font-semibold outline-none border-light_blue_800 font-montserrat h-[66px] md:h-[auto] mt-[25px] rounded-[10px] py-[11px]' >
              <option option='ENERGY_COMPANY' >Energy Company</option>
              <option option='PROPERTY_MANAGER' >Property Manager</option>
              <option option='HOME_OWNER' >Home Owner</option>
            </select>



            <div className="customer-button">
              <Button type="submit">
                {loading ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Add Customer"
                )}
              </Button>

            </div>
            <br />
            <br />
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateCustomerForm;
