import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

const AssetsTab = () => {
  const { id } = useParams();
  const [buildings, setBuildings] = useState([]);
  const token = window.localStorage.getItem("token");
  const { state } = useLocation();

  const baseUrl = process.env.REACT_APP_IMID_SERVICE_BASE_URL;

  useEffect(() => {
    const url = `${baseUrl}/building/${id}/apartments`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    fetch(url, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBuildings(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error fetching the buildings!", error);
      });
  }, [id, token]);

  return (
    <div>
      <p>Bulk Meter Number: {state?.bulkMeterNumber || 'N/A'}</p>
      <p>Meter Account Number: {state?.meterAccountNumber || 'N/A'}</p>
      <div className="mt-20 appartment-table bg-clip-border p-3 bg-white">
        <div>
          <p className="font-bold pb-3">Building Unit Information</p>
        </div>
        <div
          className="table-container"
          style={{ overflowX: "auto", width: "100%" }}
        >
          <table className="table-auto">
            <thead>
              <tr>
                <th
                  style={{ backgroundColor: "#0074BB", color: "#fff" }}
                  className="font-bold p-3 text-xs"
                >
                  IMID ID
                </th>
                <th
                  style={{ backgroundColor: "#0074BB", color: "#fff" }}
                  className="font-bold p-3 text-xs"
                >
                  IMID Number
                </th>
                <th
                  style={{ backgroundColor: "#0074BB", color: "#fff" }}
                  className="font-bold p-3 text-xs"
                >
                  IMID Mac Address
                </th>
                <th
                  style={{ backgroundColor: "#0074BB", color: "#fff" }}
                  className="font-bold p-3 text-xs"
                >
                  Meter Number
                </th>
              </tr>
            </thead>
            <tbody>
              {buildings
                .sort((a, b) => a.imidDeviceNumber - b.imidDeviceNumber) // Adjust sorting criteria as needed
                .map((apartment) => (
                  <tr key={apartment.id}>
                    <td className="p-3 text-xs">{apartment.imidDeviceId}</td>
                    <td className="p-3 text-xs">{apartment.imidDeviceNumber}</td>
                    <td className="p-3 text-xs">{apartment.imidMacAddress}</td>
                    <td className="p-3 text-xs">{apartment.meterNumber}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AssetsTab;
