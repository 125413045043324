import React, {useState} from 'react'
import LandlordProfileComponent from './LandlordProfileComponent'


const LandlordTab = () => {
    const tabs = [
        { title: 'Personal Information', content: <LandlordProfileComponent /> },
        // { title: 'Building Information', content: <HouseInformationTabComponent /> },
        // { title: 'Imid Device Details', content: <DeviceDetailsTabComponent /> },
      ];
    
      const [activeTab, setActiveTab] = useState(0);
    
      const handleTabClick = (index) => {
        setActiveTab(index);
      };
    
      return (
        <div className="tab-container">
          {/* <h1>Tab Example</h1> */}
          <div className="tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
                {index === activeTab && <hr style={{ backgroundColor: '#0074BB', height: '2px', border: 'none', marginTop: "9px" }} />}
              </div>
            ))}
          </div>
          <div className="tab-content">
            {tabs[activeTab].content}
          </div>
        </div>
      );
}

export default LandlordTab