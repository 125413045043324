import React, { useState } from "react";
import { Button, Input } from "components";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const CreatePropertyManagerForm = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const token = window.localStorage.getItem("token");

  const baseUrl = process.env.REACT_APP_IMID_SERVICE_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url =
      `${baseUrl}/user`;
    const data = {
      name,
      phoneNumber,
      emailAddress,
      buildingId,
      apartmentNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.post(url, data, { headers });
      if (res.status === 200) {
        setName("");
        setPhoneNumber("");
        setEmailAddress("");
        setBuildingId("");
        setApartmentNumber("");
      } else {
        setError("Error sending data");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="img-wrapper">
          <div className="sub-img-wrapper">
            <h3 className="building-header">Add Tenant</h3>
          </div>
        </div>
        <div className="customer-form-wrapper">
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Customer Name/Company"
              labelText="Customer Name/Company"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="email"
              labelText="Email Address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Building ID"
              type="number"
              labelText="Building ID"
              onChange={(e) => setBuildingId(e.target.value)}
            ></Input>
          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Apartment Number"
              type="number"
              labelText="Apartment Number"
              value={apartmentNumber}
              onChange={(e) => setApartmentNumber(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[377px]"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              // type="number"
              labelText="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            ></Input>

            <div className="customer-button">
              <Button type="submit">
                {loading ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Add Tenant"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreatePropertyManagerForm;
