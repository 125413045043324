import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";

const ViewBuildingInformationTab = () => {
  const { id } = useParams();
  const [buildings, setBuildings] = useState([]);
  const token = window.localStorage.getItem("token");

  const { state } = useLocation();

  const baseUrl = process.env.REACT_APP_IMID_SERVICE_BASE_URL;

  useEffect(() => {
    const url = `${baseUrl}/building/${id}/apartments`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    fetch(url, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBuildings(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error fetching the buildings!", error);
      });
  }, [id, token]);

  return (
    <div>
      <p>Contact: {state.contactName}</p>
      <p>ID: {state.id}</p>
      <p>Number Of Units: {state.numberOfApartments}</p>
      <p>Adress: {state.adress}</p>
      <p>City: {state.city}</p>
      <p>Postal Code: {state.postalCode}</p>
      <p>Province: {state.province}</p>
      <p>Country: {state.country}</p>

      <div className="mt-20 appartment-table bg-clip-border p-3 bg-white">
          <div>
            <p className="font-bold pb-3">Building Unit Information</p>
          </div>
          <div
            className="table-container"
            style={{ overflowX: "auto", width: "100%" }}
          >
            <table className="table-auto">
              <thead>
                <tr>
                  {/* <th style={{backgroundColor:"#0074BB", color:"#fff"}} className="font-bold p-3 text-xs">S/N</th> */}
                  {/* <th className="font-bold">ID</th> */}
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Name
                  </th>
                  {/* <th className="font-bold">Customer ID</th> */}
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Email
                  </th>
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Phone Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {buildings
                  .sort((a, b) => a.apartmentNumber - b.apartmentNumber)
                  .map((apartment, index) => (
                    <tr key={apartment.id}>
                      <td className="p-3 text-xs"> {apartment.name}</td>
                      <td className="p-3 text-xs"> {apartment.emailAddress}</td>
                      <td className="p-3 text-xs"> {apartment.phoneNumber}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default ViewBuildingInformationTab;
