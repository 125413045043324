import React, { useState } from "react";
import { Input, Img, Text } from "components";
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import './PersonalInfo.css'
import { useSelector } from "react-redux"

const PersonalInformationTabComponent = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.userData);
  console.log(user)

  const name = user?.name.split(' ')
  const firstName = name[0]
  const lastName = name[1]



  return (
    <>
      <div className=" w-full">

        <div className='' >
          <div className=' personalInfoBox mb-2' >
            <h3 className='profile-header mb-1'>Personal Information</h3>
            <p className='profile-sub-header'>Update your personal details here</p>
          </div>
          <div className='w-[100%]  personalInfoInputContainer' >
            <div className='personalInfoInputContainer' >
              <div className='flex personalBoxInputContainer justify-between sm:flex-col' >
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"

                  //   name="Customer Name/Company"
                  labelText='First Name'
                  // placeholder="First Name"
                  value={firstName}
                />
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                  //   name="Customer Name/Company"
                  labelText='Last Name'
                  value={lastName}
                //   name="Customer Name/Company"
                // placeholder="Last Name"
                />
              </div>
              <div className='flex personalBoxInputContainer justify-between sm:flex-col' >
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] w-[100%] text-gray-600 text-left "
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                  //   name="Customer Name/Company"
                  labelText='Email Address'
                  // placeholder="Email Address"
                  value={user?.emailAddress}
                />
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                  //   name="Customer Name/Company"

                  labelText='Phone Number'
                  //   name="Customer Name/Company"
                  // placeholder="Phone Number"
                  value={user?.phoneNumber}
                />
              </div>


              {/*<div className="header-button ml-auto mt-3 rounded-md">
                  <Button type="submit">{loading ? (
                    <ClipLoader size={20} color={"#ffffff"} />
                  ) : (
                    "Save Changes"
                  )}</Button>
                </div>*/}

              {/* <div class="md:w-2/3">
  <input
    className="bg-gray-200 border-[1px]  appearance-none 
    rounded w-5/6 py-2 px-4 
    text-gray-700 leading-tight focus:outline-none"
    id="inline-full-name"
    type="text"
    placeholder='First Name'
  />
</div> */}
            </div>
          </div>

        </div>


      </div>
    </>
  );
};

export default PersonalInformationTabComponent;
