import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import { useState } from "react";
import ProfileSettingsSevenModal from "modals/ProfileSettingsSeven";
import ProfileSettingsSidebarmenu from "components/ProfileSettingsSidebarmenu";
import ProfileSettingsColumnOne from "components/ProfileSettingsColumnOne";
import IMIDCustomerPage from "pages/IMIDCustomer";
import IMIDDevicePage from "pages/IMIDDevice";
import IMIDDataPage from "pages/IMIDData";
import TransactionsPage from "pages/Transactions";
import HelpSupportPage from "pages/HelpSupport";
import IMIDTenantPage from "pages/IMIDBuilding/IMIDBuilding";
import IMIDBuildingPage from "pages/IMIDBuilding/IMIDBuilding";
import IMIDPropertyManager from "pages/IMIDProprtyManager/IMIDPropertyManager";
import { useSelector, useDispatch } from "react-redux";
import ViewBuildings from "components/ViewBuildings/ViewBuildings";
import TenantAuthentication from "pages/TenantAuthentication/TenantAuthentication";
import TenantEmailVerification from "pages/TenantEmailVerification/TenantEmailVerification";
import TenantResetPassword from "pages/ResetPasswordTenant/TenantResetPassword";
import TenantProfileSettings from "pages/TenantProfileSettings/TenantProfileSettings";
import TenantChart from "pages/TenantChart/TenantChart";
import userImage from "./assets/images/imageIcon/user.png";
import ViewTenant from "pages/ViewTenant/ViewTenant";
import BuildingDetailComponent from "components/BuildingDetailComponent/BuildingDetailComponent";
import AutoLogout from "components/AutoLogout/AutoLogout";

import LandlordProfileSettingsComponent from "components/LandlordProfile/LandlordProfileSettingsComponent";
import CheckTokenExpiration from "components/TokenExpiration/CheckTokenExpiration";
import { logoutUser } from "./Redux/authAction";
import BuildingList from "components/BuildingLists/BuildingList";
import BuildingInfo from "pages/BuildingInfo/BuildingInfo";

const DashbaordThree = React.lazy(() => import("pages/DashbaordThree"));
const DashbaordTwo = React.lazy(() => import("pages/DashbaordTwo"));
const IMIDCustomerSeven = React.lazy(() => import("pages/IMIDCustomerSeven"));
const IMIDCustomerThree1 = React.lazy(() => import("pages/IMIDCustomerThree1"));
const IMIDCustomerFive = React.lazy(() => import("pages/IMIDCustomerFive"));
const IMIDCustomerFour = React.lazy(() => import("pages/IMIDCustomerFour"));
const IMIDCustomerThree = React.lazy(() => import("pages/IMIDCustomerThree"));
const IMIDCustomerTwo1 = React.lazy(() => import("pages/IMIDCustomerTwo1"));
const IMIDTenant = React.lazy(() => import("pages/IMIDTenant/IMIDTenant"));
const IMIDCustomerTwo = React.lazy(() => import("pages/IMIDCustomerTwo"));
const ProfileSettingsFour = React.lazy(() =>
  import("pages/ProfileSettingsFour")
);
const ProfileSettingsThree = React.lazy(() =>
  import("pages/ProfileSettingsThree")
);
const ProfileSettingsareyousureOne = React.lazy(() =>
  import("pages/ProfileSettingsareyousureOne")
);
const ProfileSettingsTwo = React.lazy(() => import("pages/ProfileSettingsTwo"));
const ProfileSettingsareyousureTwo = React.lazy(() =>
  import("pages/ProfileSettingsareyousureTwo")
);
const ProfileSettings = React.lazy(() => import("pages/ProfileSettings"));
const PasswordResetsuccessfully = React.lazy(() =>
  import("pages/PasswordResetsuccessfully")
);
const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
const ForgetPassword = React.lazy(() => import("pages/ForgetPassword"));
const AccountCreatedSuccessfully = React.lazy(() =>
  import("pages/AccountCreatedSuccessfully")
);
const VerifyAccount = React.lazy(() => import("pages/VerifyAccount"));
const CreateAccount = React.lazy(() => import("pages/CreateAccount"));
const Login = React.lazy(() => import("pages/Login"));

const BillingDoc = React.lazy(() => import("pages/BillingDoc"));

const ProjectRoutes = () => {
  const user = useSelector((state) => state.auth.userData);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (CheckTokenExpiration()) {
        dispatch(logoutUser());
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [dispatch]);  

  const sideBarItems = [
    {
      src: "images/img_settings.svg",
      menu: "Dashboard",
      path: "/admin-dashboard",
      role: ["ROLE_SUPER_ADMIN"],
    },
    {
      src: "images/img_settings.svg",
      menu: "Dashboard",
      path: "/buildings",
      role: ["ROLE_ADMIN"],
    },
    {
      src: "images/img_settings.svg",
      menu: "Dashboard",
      path: "/imidtenant",
      role: ["ROLE_USER"],
    },
    {
      src: "images/img_settings_gray_301.svg",
      menu: "IMID Device",
      path: "/imiddevice",
      role: ["ROLE_SUPER_ADMIN"],
    },

      // {
      //   src: "images/img_settings_gray_301.svg",
      //   menu: "All Units",
      //   path: "/imiddevice",
      //   role: ["ROLE_ADMIN"],
      // },
      { 
        src: "images/img_computer.svg", 
        menu: "IMID Data", 
        path: "/imiddata",
        role: ["ROLE_SUPER_ADMIN"],
      },
      {
        src: "images/img_settings_gray_301_24x24.svg",
        menu: "IMID Customers",
        path: "/imidcustomer",
        role: ["ROLE_SUPER_ADMIN"],
      },
      // {
      //   src: "images/img_settings_gray_301_24x24.svg",
      //   menu: "Tenants",
      //   path: "/imidcustomer",
      //   role: ["ROLE_ADMIN"],
      // },
      {
        src: "images/img_settings_gray_301_24x24.svg",
        menu: "Buildings",
        path: "/building/view",
        role: ["ROLE_ADMIN"],
      },
      { 
        src: "images/img_file.svg", 
        menu: "Transactions", 
        path: "/transactions",
        role: ["ROLE_SUPER_ADMIN"],  
      },
      {
        src: "images/img_user.svg",
        menu: "Profile Settings",
        path: "/profilesettings",
        role: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
      },
      {
        src: "images/img_user.svg",
        menu: "Profile Settings",
        path: "/tenant-profilesettings",
        role: ["ROLE_USER"],
      },
      // {
      //   src: "images/img_question.svg",
      //   menu: "Help & support",
      //   path: "/help-support",
      //   role: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"],
      // }
    ];

  const [showMenu, setShowMenu] = useState(true);
  const handleNav = () => {
    setShowMenu((prev) => !prev);
  };

  const getSidebarMenuItemsByRole = (role) => {
    return sideBarItems.filter(item => {
      return item.role.includes(role)
    })
  }
  const sidebar = () => {
    if (isAuthenticated) {
      return (
        <ProfileSettingsSidebarmenu
          className={
            showMenu
              ? "bg-white_A700 flex md:flex-1 flex-col md:hidden items-center justify-start p-[30px] md:px-[20px] shadow-bs md:w-[100%] w-[auto] h-[1029px]"
              : "fixed left-0 top-[6rem] z-20 bg-white_A700 flex flex-col items-center justify-start px-[30px] py-6 shadow-bs w-[250px] h-[929px]"
          }
          items={getSidebarMenuItemsByRole(user.role)}
        />
      );
    }
    return <></>;
  };

  const header = () => {
    if (isAuthenticated) {
      return (
        <ProfileSettingsColumnOne
          className="fixed flex w-[100%] max-w-full z-20 top-0 left-0 bg-white_A700 border-[1px] h-[6rem] border-gray_300 border-solid"
          userRole={user.role}
          userEmail={user.emailAddress}
          image={userImage}
          handleNav={handleNav}
          showMenu={showMenu}
        />
      );
    }
    return <></>;
  };

  //this is where we call the autologout function
  

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <div className="bg-gray_50 flex flex-col font-montserrat items-center justify-start mx-[auto] w-[100%]">
        {header()}
        <Router>
          <AutoLogout />
          <div className="flex md:flex-col flex-row gap-[37px] items-start justify-start sm:pr-[20px] pr-[24px] md:pr-[40px] w-[100%]">
            <div className="flex md:flex-col flex-row gap-[37px] items-start justify-start sm:pr-[20px] pr-[24px] md:pr-[40px] w-[35%]">
              {sidebar()}
            </div>
            <div className="flex flex-col items-start justify-start  ml-[20px] mr-[auto] mt-[6rem] pr-0 md:px-[20px] w-[100%] z-[1]">
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Login />} />
                <Route
                  path="/tenant-login"
                  element={isAuthenticated ? null : <TenantAuthentication />}
                />
                <Route
                  path="/tenant-email-verification"
                  element={
                    isAuthenticated ? null : <TenantEmailVerification />
                  }
                />
                <Route
                  path="/tenant-reset-password"
                  element={isAuthenticated ? null : <TenantResetPassword />}
                />
                <Route
                  path="/imidtenant"
                  element={isAuthenticated ? <IMIDTenant /> : <Login />}
                />
                <Route
                  path="/imidtenant/bill/:id"
                  element={isAuthenticated ? <BillingDoc /> : <Login />}
                />
                <Route
                  path="/tenant-profilesettings"
                  element={
                    isAuthenticated ? <TenantProfileSettings /> : <Login />
                  }
                />
                <Route path="/verifyaccount" element={<VerifyAccount />} />
                <Route path="/forgetpassword" element={<ForgetPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route
                  path="/logout"
                  element={
                    isAuthenticated ? <ProfileSettingsSevenModal /> : <Login />
                  }
                />
                <Route path="/createaccount" element={<CreateAccount />} />
                <Route path="/admin-dashboard" element={<DashbaordTwo />} />
                <Route>
                  <Route path="/forgetpassword" element={<ForgetPassword />} />
                  <Route path="/resetpassword" element={<ResetPassword />} />
                  <Route
                    path="/imidbuilding"
                    element={
                      isAuthenticated ? <IMIDBuildingPage /> : <Login />
                    }
                  />
                  <Route
                    path="/tenant/charts/:meterNumber"
                    element={
                      isAuthenticated ? <TenantChart /> : <Login />
                    }
                  />
                  <Route
                    path="/tenants"
                    element={
                      isAuthenticated ? <ViewTenant /> : <Login />
                    }
                  />
                  <Route
                    path="/building/:id/apartments"
                    element={
                      isAuthenticated ? <BuildingDetailComponent /> : <Login />
                    }
                  />

<Route
                    path="/building/:id/info"
                    element={
                      isAuthenticated ? <BuildingInfo /> : <Login />
                    }
                  />

                  <Route
                    path="/imiddevice"
                    element={isAuthenticated ? <IMIDDevicePage /> : <Login />}
                  />
                  <Route
                    path="/imiddata"
                    element={isAuthenticated ? <IMIDDataPage /> : <Login />}
                  />
                  <Route
                    path="/imidcustomer"
                    element={isAuthenticated ? <IMIDCustomerPage /> : <Login />}
                  />
                  <Route
                    path="/create-tenant"
                    element={
                      isAuthenticated ? <IMIDPropertyManager /> : <Login />
                    }
                  />
                  <Route
                    path="/transactions"
                    element={isAuthenticated ? <TransactionsPage /> : <Login />}
                  />
                  <Route
                    path="/help-support"
                    element={
                      isAuthenticated ? <HelpSupportPage /> : <Login />
                    }
                  />
                  <Route
                    path="/profilesettings"
                    element={isAuthenticated ? <LandlordProfileSettingsComponent /> : <Login />}
                  />
                </Route>
                <Route
                  path="/accountcreatedsuccessfully"
                  element={<AccountCreatedSuccessfully />}
                />
                <Route
                  path="/passwordresetsuccessfully"
                  element={<PasswordResetsuccessfully />}
                />
                <Route
                  path="/profilesettingsareyousuretwo"
                  element={
                    isAuthenticated ? <ProfileSettingsareyousureTwo /> : <Login />
                  }
                />
                <Route
                  path="/profilesettingstwo"
                  element={isAuthenticated ? <ProfileSettingsTwo /> : <Login />}
                />
                <Route
                  path="/profilesettingsareyousureone"
                  element={
                    isAuthenticated ? <ProfileSettingsareyousureOne /> : <Login />
                  }
                />
                <Route
                  path="/profilesettingsthree"
                  element={isAuthenticated ? <ProfileSettingsThree /> : <Login />}
                />
                <Route
                  path="/profilesettingsfour"
                  element={isAuthenticated ? <ProfileSettingsFour /> : <Login />}
                />
                <Route
                  path="/imidcustomertwo"
                  element={isAuthenticated ? <IMIDCustomerTwo /> : <Login />}
                />
                <Route
                  path="/imidcustomertwo1"
                  element={isAuthenticated ? <IMIDCustomerTwo1 /> : <Login />}
                />
                <Route
                  path="/imidcustomerthree"
                  element={isAuthenticated ? <IMIDCustomerThree /> : <Login />}
                />
                <Route
                  path="/imidcustomerfour"
                  element={isAuthenticated ? <IMIDCustomerFour /> : <Login />}
                />
                <Route
                  path="/imidcustomerfive"
                  element={isAuthenticated ? <IMIDCustomerFive /> : <Login />}
                />
                <Route
                  path="/imidcustomerthree1"
                  element={isAuthenticated ? <IMIDCustomerThree1 /> : <Login />}
                />
                <Route
                  path="/imidcustomerseven"
                  element={isAuthenticated ? <IMIDCustomerSeven /> : <Login />}
                />
                <Route
                  path="/dashbaordthree"
                  element={isAuthenticated ? <DashbaordThree /> : <Login />}
                />
                <Route
                  path="/buildings"
                  element={isAuthenticated ? <ViewBuildings /> : <Login />}
                />
                <Route
                  path="/building/view"
                  element={isAuthenticated ? <BuildingList /> : <Login />}
                />
              </Routes>
            </div>
          </div>
        </Router>
      </div>
    </React.Suspense>
  );
};
export default ProjectRoutes;
